import React, { createContext, useContext, useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { localdb, statusCode, TOKEN_HEADER_KEY } from "../util";
import { axiosHttp } from "../AxiosWrapper";
import { Modal } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    let redirectPath = location.state?.path;
    const [user, setUser] = useState(null);

    const login = (userData, forceLogin) => {
        if (forceLogin) {
            return forceLoginConfirm(login);
        }
        if (!userData || !userData.user) {
            return navigate('/dss/login', { state: { path: location.pathname } });
        }
        setUser(userData.user);
        if (userData.token) {
            localdb.put(TOKEN_HEADER_KEY, userData.token);
        }
        //adding loggedIn time
        userData.user.loggedOn = Date.now();
        localdb.put("user", JSON.stringify(userData.user));

        if ("/dss/login" === redirectPath || "/dss/register") {
            redirectPath = null;
        }

        let path = redirectPath || (userData?.user?.permConfig?.role ? "/admin" : "/");
        navigate(path, { replace: true });
    };
    const logout = () => {
        clearUser();
        return navigate('/dss/login');
    };

    const clearUser = ()=>{
        localdb.remove("user");
        localdb.remove(TOKEN_HEADER_KEY);
    }

    const isLoggedIn = () => {
        let user = JSON.parse(localdb.get("user"));
        if (!user || !user.loggedOn || !user.sessionExpiredIn) {
            return false;
        }
        return (Date.now() - user.loggedOn) < parseInt(user.sessionExpiredIn);
    }

    const getUser = () => {
        return JSON.parse(localdb.get("user"));
    }

    const unAuthorized = () => {
        return navigate('/dss/forbidden');
    }
    const completeProfile = () => {
        return navigate('/dss/completeProfile');
    }

    const refreshToken = () => {
        axiosHttp.get('/api/dss/v1/refreshToken')
            .then(res => {
                if (res.data.status === statusCode.OK) {
                    login(res.data.content);
                } else {
                    login();
                }
            })
            .catch(res => { })
    }

    return <AuthContext.Provider value={{ getUser, login, logout, isLoggedIn, unAuthorized, completeProfile, refreshToken, clearUser }}>{children}</AuthContext.Provider>;
};
export const useAuth = () => {
    return useContext(AuthContext);
};

const forceLoginConfirm = (login) => {
    Modal.confirm({
        width: 520,
        icon: <InfoCircleFilled  style={{color:"#1677ff"}}/>,
        centered:true,
        title: 'Login Required',
        content: 'Please login to interact with us!. When clicked the OK button, you may routed to the login page. See you soon',
        okText: 'Ok',
        cancelText: 'Cancel',
        onOk() {
            login();
        },
        onCancel() {},
    });
};