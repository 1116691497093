import './App.css';
import Themeroutes from "./routes/Router";
import { useAppContext } from "./provider/AppContextProvider";
import { useRoutes } from "react-router-dom";
import { Modal } from 'antd';
import { useEffect } from 'react';
import { axiosHttp } from './AxiosWrapper';
import { themeUtil } from './util';

function App() {
  const routing = useRoutes(Themeroutes);
  const { toggleError, showError } = useAppContext();

  useEffect(() => {
    axiosHttp.get("/api/public/config/v1/getTheme")
      .then((res) => {
        if (res.data.content) {
          themeUtil.updateTheme(res.data.content);
        }
      })
      .catch((error) => { 
        themeUtil.updateTheme();
      })
  }, [])

  return (
    <>
      <div className="dark">{routing}</div>

      {/*Common error message modal */}
      <Modal title={<i className="error-text bi bi-x-circle"><text className="icon-text">Something went wrong!</text></i>} open={showError} onOk={() => toggleError(false)} closable={false} cancelButtonProps={{ style: { display: 'none' } }}>
        We are working on getting this fixed as soon as we can. You may be able to try again.
      </Modal>
    </>
  );

}

export default App;
