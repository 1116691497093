import { createContext, useContext, useState } from "react";

const AppContext = createContext(null);

export const AppContextProvider = ({ children }) => {
    const [showError, setShowError] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    
    const toggleError = (showFlag, errorResponse) => {
        setShowError(showFlag);
    }

    return <AppContext.Provider value={{toggleError, showError, showMobileMenu, setShowMobileMenu }}>{children}</AppContext.Provider>

}

export const useAppContext = () => {
    return useContext(AppContext);
};