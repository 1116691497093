import { useEffect } from "react";
import { useAuth } from "../provider/Authprovider";
import { useAppContext } from "../provider/AppContextProvider";
//import axios from "axios";
import { axiosHttp } from "../AxiosWrapper";
import { useNavigate } from "react-router-dom";

import { Modal } from 'antd';

const errorModal = () => {
    Modal.error({
       centered:true,
       width:520,
      title: 'Something went wrong!',
      content: 'We are working on getting this fixed as soon as we can. You may be able to try again.',
    });
};
const AxiosErrorHandler = ({ children }) => {
    const {login, unAuthorized, completeProfile, clearUser} = useAuth();
    const {toggleError} = useAppContext();
    const navigate = useNavigate();
    useEffect(() => {
        const responseInterceptor = axiosHttp.interceptors.response.use(response =>{ 
                return response
           
        }, (error) => {
            if (error.status === 401){//authentication
                 //login(null, true);//forceLogin
                 //login();
                 clearUser();
                 navigate("/");
            }else if(error.status === 403){//Authorization
                unAuthorized();
            }else if(error.status === 404){//No Found
                
            }else if(error.status === 423){//Profile not completed
                completeProfile();
            }
            else{
               // toggleError(true, error);// all other internal errors
               errorModal();
            }
            return Promise.reject(error)
        })

        return () => {
            axiosHttp.interceptors.response.eject(responseInterceptor);
        }
    }, [])

    return children
}
export default AxiosErrorHandler;



/*axiosHttp.interceptors.response.use(
        response => {
            return response
          },function (error) {
            if (error.status === 401){
                useAuth.login();
                
            }else if(error.status === 403){//Authorization
                useAuth.unAuthorized();
           }else if(error.status === 423){//Profile not completed
                window.location.href = '/dss/completeProfile';
            }
            else{
                errorModal();
            }
            return Promise.reject(error)
          }
    );*/

